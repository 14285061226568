:root {
  --base-black: #000000;
  --base-white: #ffffff;
  --grey-25: #fcfdfd;
  --grey-50: #f9fafb;
  --grey-100: #f2f4f7;
  --grey-200: #eaecf0;
  --grey-300: #d0d5dd;
  --grey-400: #98a2b3;
  --grey-500: #667085;
  --grey-600: #475467;
  --grey-700: #344054;
  --grey-800: #1d2939;
  --grey-900: #101828;
  --grey-900_60: rgba(16, 24, 40, 0.6);
  --grey-900_80: rgba(16, 24, 40, 0.8);
  --purple-25: #fafaff;
  --purple-50: #f4f3ff;
  --purple-100: #ebe9fe;
  --purple-200: #d9d6fe;
  --purple-300: #bdb4fe;
  --purple-400: #9b8afb;
  --purple-500: #7a5af8;
  --purple-600: #6938ef;
  --purple-700: #5925dc;
  --purple-800: #4a1fb8;
  --purple-900: #3e1c96;
  --rose-25: #fff5f6;
  --rose-50: #fff1f3;
  --rose-100: #ffe4e7;
  --rose-200: #ffcbd7;
  --rose-300: #fea3b4;
  --rose-400: #fe6f8e;
  --rose-500: #f73d68;
  --rose-600: #e21c54;
  --rose-700: #bf1048;
  --rose-800: #a11043;
  --rose-900: #88123e;
  --blue-25: #f5fbff;
  --blue-50: #f1f9fe;
  --blue-100: #e0f2fe;
  --blue-200: #b9e6fe;
  --blue-300: #7cd4fd;
  --blue-400: #36bff9;
  --blue-500: #0ba5ec;
  --blue-600: #0086c8;
  --blue-700: #026aa1;
  --blue-800: #065986;
  --blue-900: #0b4b70;
  --green-25: #f6fef9;
  --green-50: #edfdf2;
  --green-100: #d3f8df;
  --green-200: #aaf0c4;
  --green-300: #73e2a3;
  --green-400: #3ccb7f;
  --green-500: #16b364;
  --green-600: #099250;
  --green-700: #087443;
  --green-800: #095c37;
  --green-900: #084c2e;
  --orange-25: #fff9f5;
  --orange-50: #fff4ed;
  --orange-100: #ffe6d5;
  --orange-200: #ffd6ae;
  --orange-300: #ff9c66;
  --orange-400: #ff692e;
  --orange-500: #ff4405;
  --orange-600: #e62e05;
  --orange-700: #bc1b06;
  --orange-800: #97180c;
  --orange-900: #771a0d;
  --ui-purple: #6610f2;
  --ui-purple_70: rgba(102, 16, 242, 0.7);
  --ui-purple_light: #f3ecfe;
  --ui-black: #222222;
  --ui-black_30: rgba(34, 34, 34, 0.3);
  --ui-black_80: rgba(34, 34, 34, 0.8);
  --ui-light_orange: #fcf4ed;
  --ui-light_blue: #e7e9fe;
  --ui-line_grey: #e0e0e0;
  --ui-light_grey: #f5f5f5;
  --ui-light_grey_70: rgba(245, 245, 245, 0.7);
  --ui-light_grey_2: #f7f7f7;
  --ui-light_grey_3: #ebebeb;
  --ui-white: #ffffff;
  --ui-white_30: rgba(255, 255, 255, 0.3);
  --ui-green: #51b463;
  --ui-light_green: #edf7ef;
  --ui-pale_purple: rgba(249, 245, 254, 1);
  --ui-red: #d52714;
  --ui-rose: #f73d68;
  --ui-alert_green: #16b364;
  --text-purple: #6610f2;
  --text-black: #222222;
  --text-grey: #666666;
  --text-grey_200: #eaecf0;
  --text-grey_600: #475467;
  --text-grey_900: #101828;
  --text-light_grey: #bcbcbc;
  --text-light_grey_2: #f7f7f7;
  --text-white: #ffffff;
  --text-red: #d52714;
  --text-blue: #152aed;
  --text-orange: #d06d17;
  --text-green: #1e9033;
  --icons-purple: #6610f2;
  --icons-black: #222222;
  --icons-grey: #666666;
  --icons-white: #ffffff;
  --icons-red: #dc766b;
  --icons-green: #51b463;
  --dark-purple: #5c4f73;
  --color-red: #fd6262;
  --transparent: rgba(0, 0, 0, 0);
  --transparent-black: rgba(0, 0, 0, 0.5);
  --transparent-icons_grey_20: rgba(102, 102, 102, 0.2);
  --colorful-bg: radial-gradient(
    141.42% 141.42% at 100% 0%,
    #f5f7ff 0%,
    #fff6ed 100%
  );
  --base-gradient: linear-gradient(
    93deg,
    #ce67ff 8.61%,
    #c13fff 23.66%,
    #b415ff 40.56%,
    #8e35ff 75.04%,
    #d52fff 104.39%
  );
  --grey-gradient: linear-gradient(
    180deg,
    #f4f3ff 0%,
    rgba(244, 243, 255, 0) 100%
  );
  --neutral-alpha-2: #00005506;
  --neutral-alpha-3: #00104010;
  --neutral-alpha-4: #000b3618;
  --neutral-alpha-5: #0009321f;
  --neutral-alpha-6: #00073527;
  --neutral-alpha-7: #00063332;
  --neutral-alpha-8: #00083046;
  --neutral-alpha-9: #00051d74;
  --neutral-alpha-10: #00051b7f;
  --neutral-11: #62636c;
  --neutral-alpha-11: #0002119d;
  --neutral-12: #1e1f24;
  --neutral-2: #f9f9fb;
  --neutral-3: #eff0f3;
  --neutral-4: #e7e8ec;
  --neutral-alpha-12: #000107e1;


  --danger-alpha-6: #e4010139;

  --gradient-14: linear-gradient(to right, #ff5fe5 0%, #ff8a02 100%);
  --warning-11: #915930;
  --warning-9: #ffc53d;
  --warning-3: #fff3d0;
  --overlay-dark-7: #0000002b;
  --panel-3: #f9f9fb;
  --neutral-5: #e0e1e6;
  --neutral-8: #b9bbc6;
  --neutral-9: #8b8d98;
  --neutral-10: #80828d;
  --neutral-7: #cdced7;
  --neutral-1: #fcfcfd;
  --danger-9: #e5484d;
  --danger-10: #d93d42;
  --danger-11: #c62a2f;
  --danger-12: #641723;
  --danger-3: #ffefef;
  --primary-9: #9552f4;
  --primary-12: #3e196e;
  --accent-9: #0a60ff;
  --accent-12: #0d2d6a;
  --success-9: #46a758;
  --success-12: #203c25;
  --success-3: #e9f9e7;
  --success-11: #297c3b;
  --primary-alpha-8: #5400f96e;
  --primary-alpha-11: #5500c3c6;
  --primary-alpha-12: #29005ee6;
  --primary-alpha-3: #5a00ff11;
  --accent-alpha-8: #005cff8b;
  --accent-alpha-11: #0050e7f2;
  --accent-alpha-12: #002262f2;
  --accent-alpha-3: #0062ff15;
  --danger-alpha-8: #d100036f;
  --danger-alpha-11: #bb0007d5;
  --danger-alpha-12: #55000de8;
  --danger-alpha-3: #ff010110;
  --danger-alpha-4: #ff00001a;
  --success-alpha-8: #008d1a9a;
  --success-alpha-11: #006316d6;
  --success-alpha-12: #002006df;
  --success-alpha-3: #02b30214;
  --overlay-dark-11: #0000009b;
  --overlay-dark-12: #000000df;
  --panel-translucent: #ffffff;
  --panel-1: #fcfcfd;
  --background-1: #fcfcfd;
}

.dark {
  --neutral-alpha-1: #ffffff02;
  --neutral-alpha-10: #fcfcfc66;
  --neutral-alpha-11: #fcfcfcb2;
  --neutral-alpha-12: #fcfcfcf0;
  --neutral-alpha-2: #fafafa0f;
  --neutral-alpha-3: #e5e5e51a;
  --neutral-alpha-4: #ffffff1c;
  --neutral-alpha-5: #ffffff21;
  --neutral-alpha-6: #f2f2f22b;
  --neutral-alpha-7: #fafafa33;
  --neutral-alpha-8: #fafafa47;
  --neutral-alpha-9: #fcfcfc59;

  --neutral-1: #000000;
  --neutral-10: #656565;
  --neutral-11: #b1b1b1;
  --neutral-12: #eeeeee;
  --neutral-2: #0f0f0f;
  --neutral-3: #171717;
  --neutral-4: #1c1c1c;
  --neutral-5: #212121;
  --neutral-6: #292929;
  --neutral-7: #323232;
  --neutral-8: #464646;
  --neutral-9: #575757;

  --danger-1: #1f1315;
  --danger-10: #f26669;
  --danger-11: #ff8589;
  --danger-12: #eeeef0;
  --danger-2: #291618;
  --danger-3: #3b191d;
  --danger-4: #481a20;
  --danger-5: #551c22;
  --danger-6: #691d25;
  --danger-7: #8c1d28;
  --danger-8: #d21e24;
  --danger-9: #e5484d;

  --danger-alpha-1: #ee91a021;
  --danger-alpha-10: #ff6b70f2;
  --danger-alpha-11: #ff858a;
  --danger-alpha-12: #fcfcfdf2;
  --danger-alpha-2: #f1838e2b;
  --danger-alpha-3: #f66a7a3d;
  --danger-alpha-4: #f8596d4a;
  --danger-alpha-5: #fa526357;
  --danger-alpha-6: #fa47596b;
  --danger-alpha-7: #ff33478c;
  --danger-alpha-8: #fd262fd4;
  --danger-alpha-9: #fe5257e5;

  --success-1: #0d1912;
  --success-10: #5cbc6e;
  --success-11: #71d083;
  --success-12: #c2f0c2;
  --success-2: #131d16;
  --success-3: #18281d;
  --success-4: #1b3021;
  --success-5: #1e3926;
  --success-6: #24452d;
  --success-7: #2d5d39;
  --success-8: #428a4f;
  --success-9: #46a758;

  --success-alpha-1: #85fab51a;
  --success-alpha-10: #79fe93bd;
  --success-alpha-11: #89fe9ed1;
  --success-alpha-12: #cefdcef2;
  --success-alpha-2: #9cf2b61f;
  --success-alpha-3: #94fab429;
  --success-alpha-4: #90fdaf30;
  --success-alpha-5: #82f8a53b;
  --success-alpha-6: #83f6a247;
  --success-alpha-7: #79fb995e;
  --success-alpha-8: #79fb918c;
  --success-alpha-9: #68fd84a8;

  --primary-1: #171320;
  --primary-10: #8a5ce7;
  --primary-11: #c399ff;
  --primary-12: #ecddfe;
  --primary-2: #1d152d;
  --primary-3: #261a42;
  --primary-4: #2d1e4f;
  --primary-5: #31205c;
  --primary-6: #37246e;
  --primary-7: #472c8f;
  --primary-8: #633bce;
  --primary-9: #693edd;
  --primary-alpha-1: #b292f621;
  --primary-alpha-10: #9967fee8;
  --primary-alpha-11: #c399ff;
  --primary-alpha-12: #ecdcfe;
  --primary-alpha-2: #a175fa2e;
  --primary-alpha-3: #9162fe42;
  --primary-alpha-4: #9061ff4f;
  --primary-alpha-5: #8558f95e;
  --primary-alpha-6: #7c52fa70;
  --primary-alpha-7: #7d4bfb91;
  --primary-alpha-8: #7948fecf;
  --primary-alpha-9: #7948fede;

  --warning-1: #1f1300;
  --warning-10: #ffcb47;
  --warning-11: #ffcc4d;
  --warning-12: #ffe7b3;
  --warning-2: #251804;
  --warning-3: #30200b;
  --warning-4: #39270f;
  --warning-5: #432e12;
  --warning-6: #533916;
  --warning-7: #6f4d1d;
  --warning-8: #a9762a;
  --warning-9: #ffc53d;
  --warning-alpha-1: #f0940021;
  --warning-alpha-10: #ffcb47;
  --warning-alpha-11: #ffcd4d;
  --warning-alpha-12: #ffe7be;
  --warning-alpha-2: #f7a01d26;
  --warning-alpha-3: #fda93a30;
  --warning-alpha-4: #f8a83f3b;
  --warning-alpha-5: #f8aa4445;
  --warning-alpha-6: #fcad4554;
  --warning-alpha-7: #fcae4070;
  --warning-alpha-8: #fcb140ab;
  --warning-alpha-9: #ffc53d;

  --accent-1: #131620;
  --accent-10: #5c7ae7;
  --accent-11: #99a7ff;
  --accent-12: #dde0fe;
  --accent-2: #151a2d;
  --accent-3: #1a2442;
  --accent-4: #1e2a4f;
  --accent-5: #20305c;
  --accent-6: #243a6e;
  --accent-7: #2c478f;
  --accent-8: #3b64ce;
  --accent-9: #3e6add;
  --accent-alpha-1: #92aaf621;
  --accent-alpha-10: #6788fee8;
  --accent-alpha-11: #99a7ff;
  --accent-alpha-12: #dcdffe;
  --accent-alpha-2: #7590fa2e;
  --accent-alpha-3: #6289fe42;
  --accent-alpha-4: #6186ff4f;
  --accent-alpha-5: #5883f95e;
  --accent-alpha-6: #5284fa70;
  --accent-alpha-7: #4b7dfb91;
  --accent-alpha-8: #487cfecf;
  --accent-alpha-9: #487cfede;

  --overlay-dark-1: #00000003;
  --overlay-dark-10: #0000007f;
  --overlay-dark-11: #0000009b;
  --overlay-dark-12: #000000df;
  --overlay-dark-2: #00000006;
  --overlay-dark-3: #0000000e;
  --overlay-dark-4: #00000014;
  --overlay-dark-5: #0000001b;
  --overlay-dark-6: #00000023;
  --overlay-dark-7: #0000002b;
  --overlay-dark-8: #00000044;
  --overlay-dark-9: #00000072;

  --gradient-14: linear-gradient(to right, #ff5fe5 0%, #ff8a02 100%);
  --panel-3: #171717;
  --panel-1: #000000;
  --panel-translucent: #000000;
  --background-1: #000000;
}
