@import 'external.css';
@import './_colors.scss';
@import '~react-range-slider-input/dist/style.css';
@import 'intro.js/introjs.css';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--font-inter);
    font-synthesis: none;
}

body {
    color: var(--neutral-12);
    overflow-y: scroll;
}

input {
    color: var(--grey-900);
}


a {
    text-decoration: none;
    color: inherit;
}

ul,
ul li,
ul li {
    list-style: none;
   margin:0;
   padding: 0;
   text-indent: 0;
   list-style-type: 0;
}

body {
   background-color: var(--background-1);
}

.toast {
    border-radius: 16px !important;
    background-color: var(--neutral-alpha-4) !important;
    color: var(--neutral-12) !important;
}

hr {
    border-top: 0.4px solid var(--gery-300);
}

:root {
    --toastify-toast-width: auto !important;
}

.sprintful-overlay {
    z-index: 1000000000;
}

.scroll-lock {
    overflow: hidden;

}

.Toastify__toast {
    background-color: #FFFFFF !important;
    border: 1px solid #000B3618 !important;
    font-family: var(--font-inter);
    padding: 5px !important;
    margin: 1px !important;
    min-height: 10px !important;
}

.Toastify__toast-body{
    font-family: var(--font-inter);
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    text-align: left;
    color: var(--neutral-12);
}

.Toastify__close-button--light {
    margin-top: 10px !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
} 
