@import 'https://app.sprintful.com/widget/v1.css';
@import 'https://fonts.googleapis.com/css2?family=DM+Mono:wght@500&display=swap';

/* dummy rule to force import at top, weird bug */
body {
  opacity: 1;
}

.slick-slider {
  width: 100%;
}

.slick-slide {
  margin-right: 8px;
}

.slick-list {
  padding: 0 !important;
}

.intro-tooltip {
  border: 1px solid var(--grey-900);
  border-radius: 16px;
  width: 416px !important;
  max-width: 416px !important;
  box-shadow: none !important;
}

.introjs-arrow {
  display: none !important;
}

.introjs-tooltiptext {
  font-size: 15px;
  color: var(--grey-500);
  padding-top: 0 !important;
  padding-bottom: 8px !important;
}

.introjs-helperNumberLayer {
  padding-top: 0px !important;
}

.introjs-tooltipbuttons {
  border: none !important;
}